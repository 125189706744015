
import {
    defineComponent,
    reactive,
    ref,
    nextTick,
    provide,
    computed,
} from 'vue';
import { QueryListType, SearchListType } from '@/views/DelayCallback/types';
import SingleLicenseUpload from '@/views/DelayCallback/Retail/components/singleLicenseUpload.vue';
import BatchLicenseUpload from '@/views/DelayCallback/Retail/components/batchLicenseUpload.vue';
import ProveUpload from '@/views/DelayCallback/Retail/components/proveUpload.vue';
import NoticeModal from '@/components/NoticeModal.vue';
import {
    getfileSize,
    cancelUploadFile,
    getPicBasic,
    getSearchResult,
    submitInformation,
} from '@/API/delayCallback/overdueSearch';
import setColumnWidth from '@/utils/rv/setColumnWidth';
import base64toBlob from '@/utils/rv/base64toBlob';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
// 表格数据
const columns = reactive([
    { title: '车架号', dataIndex: 'vinNo', align: 'center', width: 168 },
    { title: '车型', dataIndex: 'model', align: 'center', width: 100 },
    { title: '年款', dataIndex: 'typeClass', align: 'center', width: 100 },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        align: 'center',
        width: 104,
    },
    { title: '零售类型', dataIndex: 'usage', align: 'center', width: 104 },
    { title: '零售日期', dataIndex: 'retailDate', align: 'center', width: 100 },
    {
        title: '延期截止日期',
        dataIndex: 'extensionDeadline',
        align: 'center',
        width: 120,
    },
    {
        title: '特殊车辆用途',
        dataIndex: 'specialUsage',
        align: 'center',
        width: 100,
    },
    {
        title: '文件',
        fixed: 'right',
        width: 270,
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
    },
    {
        title: '待提交',
        fixed: 'right',
        width: 100,
        dataIndex: 'notSubmit',
        slots: { customRender: 'notSubmit' },
    },
]);
const saleArray = ['最终用户用车', '经销商用车', '全部'];

const tableWidth = window.innerWidth;
export default defineComponent({
    components: {
        SingleLicenseUpload,
        BatchLicenseUpload,
        ProveUpload,
        NoticeModal,
    },
    setup() {
        const store = useStore();
        const dealerCode = computed(
            () => store.state.user.organization.entityCode
        );
        const tableHeight = ref();
        const visible = ref<boolean>(false);
        const isBatchVisible = ref<boolean>(false);
        const subLicense = ref();
        // 打开筛选条件
        const isOpen = ref<boolean>(false);
        const searchCon = ref();
        // 批量复制
        const tableData = ref([]);
        //分页组件
        const pagination = reactive({
            pageSize: 20,
            currentPage: 1,
            total: 0,
        });
        //筛选条件，各个信息框的内容
        const searchParams = reactive<QueryListType>({
            vinNo: '',
            model: '',
            usage: '全部',
            retailPeriod: [],
        });
        // 设置搜索值
        const params = reactive<SearchListType>({
            vinNo: '',
            model: '',
            usage: '全部',
            retailStartDate: '',
            retailEndDate: '',
            current: 1,
            size: 20,
        });
        // 表格复选框逻辑
        const state = reactive<any>({
            selectedRowKeys: [], // Check here to configure the default column
            selectedRows: [],
        });
        const hasSelected = computed(() => state.selectedRowKeys.length > 0);

        // 重置查询条件
        const resetEvent = () => {
            searchParams.vinNo = '';
            searchParams.model = '';
            searchParams.usage = '全部';
            searchParams.retailPeriod = [];
            pagination.pageSize = 20;
            pagination.currentPage = 1;
        };
        const getTableScroll = () => {
            nextTick(() => {
                //  默认底部分页50 + 边距10
                const extraHeight = 110;
                const tHeader = document.getElementsByClassName('table-box')[0];
                //表格内容距离顶部的距离
                let tHeaderBottom = 0;
                if (tHeader) {
                    tHeaderBottom = tHeader.getBoundingClientRect().top;
                }
                //窗体高度-表格内容顶部的高度-表格内容底部的高度
                const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
                tableHeight.value = height;
                if (!(tableData.value && tableData.value.length > 0)) {
                    (document.getElementsByClassName(
                        'ant-table-placeholder'
                    )[0] as any).style.height = `calc(100vh - ${tHeaderBottom +
                        60}px)`;
                }
            });
        };
        // 获取params
        const getParams = () => {
            params.vinNo = '';
            params.model = '';
            params.usage = '';
            params.retailStartDate = '';
            params.retailEndDate = '';
            params.current = pagination.currentPage;
            params.size = pagination.pageSize;

            if (searchParams.vinNo) {
                params.vinNo = searchParams.vinNo.trim();
            }

            if (searchParams.model) {
                params.model = searchParams.model.trim();
            }

            if (searchParams.usage) {
                params.usage = searchParams.usage;
            }

            if (searchParams.retailPeriod.length > 0) {
                params.retailStartDate = searchParams.retailPeriod[0];
                params.retailEndDate = searchParams.retailPeriod[1];
            }
        };
        //根据筛选框信息，查询表格
        const getTableData = () => {
            getParams();
            getSearchResult(params).then((res: any): void => {
                tableData.value = res.content;
                pagination.total = res.totalElements;
                const customerColumn = [
                    'model',
                    'customerName',
                    'specialUsage',
                ];
                tableData.value.length &&
                    Object.assign(
                        columns,
                        setColumnWidth(columns, tableData.value, customerColumn)
                    );
            });
            getTableScroll();
            state.selectedRowKeys = [];
            state.selectedRows = [];
        };
        // 分页逻辑
        const pageChange = (page: number, pageSize: number) => {
            pagination.currentPage = page > 0 ? page : 1;
            pagination.pageSize = pageSize;
            getTableData();
        };
        const sizeChange = (page: number, pageSize: number) => {
            pagination.currentPage = page > 0 ? page : 1;
            pagination.pageSize = pageSize;
            getTableData();
        };

        const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        // 单张提交
        const isSubVisible = ref<boolean>(false);
        const isInvoice = ref<boolean>(false);
        const isLicense = ref<boolean>(false);
        const isRelationProof = ref<boolean>(false);
        const selectInfo = reactive([]);
        //弹窗显示
        const modalVisible = ref<boolean>(false);
        const modalData = reactive({});
        const modalParams = reactive({
            noticeType: '',
            content: '',
            btnVisible: false,
        });
        // 批量发票行驶证提交
        const batchRef = ref();
        const batchActiveKey = ref<string>('1');
        // 上传证明弹窗
        const isProveVisible = ref<boolean>(false);
        const idList = ref<any>([]);
        const proveRef = ref();
        // 关闭单张发票提交和行驶证提交弹窗
        const closeSub = (param: { isSubVisible: boolean }) => {
            isSubVisible.value = param.isSubVisible;
            sessionStorage.setItem('overdueFileSize', '');
            // 提交成功刷新数据
            getTableData();
        };
        //关闭批量上传弹窗
        const closeBatch = (param: { visible: boolean }) => {
            isBatchVisible.value = param.visible;
            getTableData();
        };
        // 关闭上传证明弹窗
        const closeProve = (param: { visible: boolean }) => {
            isProveVisible.value = param.visible;
            getTableData();
        };
        const getModalInfo = (param: {
            modalVisible: boolean;
            modalData: object;
        }) => {
            modalVisible.value = param.modalVisible;
            Object.assign(modalData, param.modalData);
        };
        // 取消上传方法
        const cancelUpload = async (
            type: string,
            batchNo: string
        ) => {
            try {
                const res = await cancelUploadFile(batchNo);
                if (res.code !== '0') {
                    modalVisible.value = true;
                    modalParams.noticeType = 'error';
                    modalParams.content = res.message;
                    modalParams.btnVisible = false;
                    Object.assign(modalData, params);
                    if (type === 'invoice') {
                        sessionStorage.setItem('overdueInvoiceSuccess', '');
                    } else if (type === 'license') {
                        sessionStorage.setItem('overdueLicenseSuccess', '');
                    } else {
                        sessionStorage.setItem('overdueRelationProofSuccess', '');
                    }
                } else {
                    if (type === 'invoice') {
                        sessionStorage.setItem(
                            'overdueInvoiceSuccess',
                            'success'
                        );
                        isBatchVisible.value = false;
                    } else if (type === 'license') {
                        sessionStorage.setItem(
                            'overdueLicenseSuccess',
                            'success'
                        );
                        isBatchVisible.value = false;
                    } else {
                        sessionStorage.setItem(
                            'overdueRelationProofSuccess',
                            'success'
                        );
                        isBatchVisible.value = false;
                    }
                }
            } catch (e) {
                modalVisible.value = true;
                modalParams.noticeType = 'error';
                modalParams.content = '取消上传失败！';
                modalParams.btnVisible = false;
                Object.assign(modalData, params);
                if (type === 'invoice') {
                    sessionStorage.setItem('overdueInvoiceSuccess', '');
                } else if (type === 'license') {
                    sessionStorage.setItem('overdueLicenseSuccess', '');
                } else {
                    sessionStorage.setItem('overdueRelationProofSuccess', '');
                }
            }
        };
        // 关闭信息弹窗
        const closeModal = (param: {
            isVisible: boolean;
            continue: boolean;
        }) => {
            modalVisible.value = param.isVisible;
            // 若continue为true 则调用取消上传方法
            if (param.continue) {
                const stopInvoice = sessionStorage.getItem(
                    'stopOverdueInvoice'
                );
                if (stopInvoice) {
                    sessionStorage.setItem('overdueInvoiceData', '');
                    cancelUpload('invoice', stopInvoice);
                } else {
                    sessionStorage.setItem('overdueInvoiceSuccess', '');
                }
                const stopLicense = sessionStorage.getItem(
                    'stopOverdueLicense'
                );
                if (stopLicense) {
                    sessionStorage.setItem('overdueLicenseData', '');
                    cancelUpload('license', stopLicense);
                } else {
                    sessionStorage.setItem('overdueLicenseSuccess', '');
                }
                const stopRelationProof = sessionStorage.getItem(
                    'stopOverdueRelationProof'
                );
                if (stopRelationProof) {
                    sessionStorage.setItem('overdueRelationProofData', '');
                    cancelUpload('relationProof', stopRelationProof);
                } else {
                    sessionStorage.setItem('overdueRelationProofSuccess', '');
                }
            }
        };
        // 上传失败 关闭信息调用子组件方法刷新页面样式
        const isResetModal = (param: { continue: boolean }) => {
            if (isBatchVisible.value) {
                batchRef.value.isResetModal({ continue: param.continue });
            }
            if (isProveVisible.value) {
                proveRef.value.isResetModal({ continue: param.continue });
            }
        };
        // 图片有问题 再次上传清空之前记录
        const isRestCount = (param: { continue: boolean }) => {
            if (isBatchVisible.value) {
                batchRef.value.isResetCount({ continue: param.continue });
            }
        };
        getTableData();
        // 发票/行驶证/证明 查看弹窗
        const pictureModal = reactive({
            pictureModalFlag: false,
            pictureModalTitle: '',
            pictureModalImg: '',
            fileName: '',
            pdf: '',
            base64Blob: {},
            isImage: true,
        });

        const viewPicEvent = (vinNo: string, type: string) => {
            if (type == 'license') {
                pictureModal.pictureModalTitle = 'RE_' + vinNo;
            } else if (type == 'invoice') {
                pictureModal.pictureModalTitle = 'IN_' + vinNo;
            } else if (type == 'prove') {
                pictureModal.pictureModalTitle = '延期证明_' + vinNo;
            } else if (type === 'relationProof') {
                pictureModal.pictureModalTitle = 'RP_' + vinNo;
            }
            const params = { vinNo, type, dealerCode: dealerCode.value };
            getPicBasic({ params }).then((res: any) => {
                let URL = {};
                pictureModal.fileName = res.docName;
                if (!res) {
                    message.error('数据为空');
                    return;
                }
                const fileName = res.docName;
                const fileUrl = res.base64;
                if (fileName.indexOf('.docx') > -1) {
                    pictureModal.isImage = false;
                    URL = base64toBlob(
                        fileUrl,
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    );
                    pictureModal.pdf = window.URL.createObjectURL(URL);
                } else if (fileName.indexOf('.xlsx') > -1) {
                    pictureModal.isImage = false;
                    URL = base64toBlob(
                        fileUrl,
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    );
                    pictureModal.pdf = window.URL.createObjectURL(URL);
                } else if (fileName.indexOf('.jpg') > -1) {
                    pictureModal.isImage = true;
                    pictureModal.pictureModalImg =
                        'data:image/jpeg;base64,' + res.base64;
                    URL = base64toBlob(fileUrl, 'image/jpeg');
                } else if (fileName.indexOf('.pdf') > -1) {
                    pictureModal.isImage = false;
                    URL = base64toBlob(fileUrl, 'application/pdf');
                    pictureModal.pdf = window.URL.createObjectURL(URL);
                }
                pictureModal.pictureModalFlag = true;
                pictureModal.base64Blob = URL;
            });
        };
        const downloadImg = (pictureModal: any) => {
            const downloadElement = document.createElement('a');
            downloadElement.href = window.URL.createObjectURL(
                pictureModal.base64Blob
            );
            downloadElement.download = pictureModal.fileName;
            downloadElement.click();
        };
        const closePictureModal = () => {
            pictureModal.pictureModalFlag = false;
        };

        const isSubmit = (record: any) => {
            if (record.usage == '经销商用车') {
                if (
                    record.invoicePath &&
                    record.licensePath &&
                    record.certifyPath
                ) {
                    return true;
                } else {
                    return false;
                }
            } else if (record.usage == '最终用户用车') {
                if (record.invoicePath && record.certifyPath) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        const isPath = async (path: string) => {
            if (path) {
                const param = {
                    fileKey: path,
                };
                await getfileSize(param).then((res: any) => {
                    sessionStorage.setItem(
                        'overdueFileSize',
                        JSON.stringify(res)
                    );
                });
            }
        };

        const isSameBrand = () => {
            const brands = state.selectedRows.map((item: any) => {
                return item.brand;
            });
            if ([...new Set(brands)].length === 1) {
                return true;
            }
            return false;
        };

        const noLicense = ref(false);
        const noRelationProof = ref(false);
        const brand = ref('');
        const uploadVerify = async (butCon: string) => {
            if (butCon == 'notProve') {
                if (
                    state.selectedRowKeys.length > 1 ||
                    state.selectedRowKeys.length == 0
                ) {
                    const data =
                        state.selectedRowKeys.length === 0
                            ? tableData.value
                            : state.selectedRows;

                    noLicense.value = data.some((row: any) => {
                        return row.usage === '最终用户用车';
                    });
                    noRelationProof.value = noLicense.value;
                    isBatchVisible.value = true;
                } else if (state.selectedRowKeys.length == 1) {
                    if (state.selectedRows[0].usage === '最终用户用车') {
                        if (state.selectedRows[0].invoicePath) {
                            modalVisible.value = true;
                            modalParams.noticeType = 'warning';
                            modalParams.content = '请不要重复提交';
                            modalParams.btnVisible = false;
                            Object.assign(modalData, modalParams);
                        } else {
                            isInvoice.value = true;
                            isLicense.value = false;
                            isRelationProof.value = false;
                            Object.assign(selectInfo, state.selectedRows[0]);
                            isSubVisible.value = true;
                        }
                    } else {
                        if (state.selectedRows[0].invoicePath && state.selectedRows[0].licensePath && state.selectedRows[0].relationProofPath) {
                            modalVisible.value = true;
                            modalParams.noticeType = 'warning';
                            modalParams.content = '请不要重复提交';
                            modalParams.btnVisible = false;
                            Object.assign(modalData, modalParams);
                        } else {
                            isInvoice.value = true;
                            isLicense.value = true;
                            isRelationProof.value = true;
                            Object.assign(selectInfo, state.selectedRows[0]);
                            isSubVisible.value = true;
                        }
                    }
                }
            } else {
                if (state.selectedRowKeys.length == 0) {
                    modalVisible.value = true;
                    modalParams.noticeType = 'warning';
                    modalParams.content = '请至少选择一个零售车辆信息';
                    modalParams.btnVisible = false;
                    Object.assign(modalData, modalParams);
                } else {
                    if (state.selectedRowKeys.length == 1) {
                        if (state.selectedRows[0].certifyPath) {
                            modalVisible.value = true;
                            modalParams.noticeType = 'warning';
                            modalParams.content = '请不要重复提交';
                            modalParams.btnVisible = false;
                            Object.assign(modalData, modalParams);
                        } else {
                            isProveVisible.value = true;
                            idList.value = state.selectedRows[0].id;
                            brand.value = state.selectedRows[0].brand;
                        }
                    } else {
                        // 判断所选的数据是否具有相同的brand
                        if (isSameBrand()) {
                            const arr = ref<any>([]);
                            state.selectedRows.forEach((ele: any) => {
                                arr.value.push(ele.id);
                            });
                            isProveVisible.value = true;
                            idList.value = arr.value;
                            brand.value = state.selectedRows[0].brand;
                        } else {
                            message.error(
                                '批量上传证明时请选择具有相同品牌的车辆！'
                            );
                        }
                    }
                }
            }
        };

        const submitInfo = () => {
            if (tableData.value && tableData.value.length > 0) {
                const idArr = ref<any>([]);
                const arr = tableData.value;
                const selectArr = state.selectedRows;
                let isQualified = true;
                if (state.selectedRows.length > 0) {
                    selectArr.forEach((item: any) => {
                        if (isSubmit(item)) {
                            idArr.value.push(item.id);
                        } else {
                            isQualified = false;
                        }
                    });
                } else {
                    arr.forEach((item: any) => {
                        if (isSubmit(item)) {
                            idArr.value.push(item.id);
                        }
                    });
                }
                const params = {
                    vinNos: idArr.value,
                };
                if (idArr.value.length > 0) {
                    if (!isQualified) {
                        modalVisible.value = true;
                        modalParams.noticeType = 'warning';
                        modalParams.content = '当前存在不可提交的零售信息';
                        modalParams.btnVisible = false;
                        Object.assign(modalData, modalParams);
                        isQualified = true;
                    } else {
                        submitInformation(idArr.value).then((res: any) => {
                            modalVisible.value = true;
                            if (res.code !== '0') {
                                modalParams.noticeType = 'warning';
                                // sessionStorage.setItem("muchContent", JSON.stringify(res.data));
                                modalParams.content = res.message;
                                modalParams.btnVisible = false;
                            } else {
                                modalParams.noticeType = 'success';
                                modalParams.content = '提交成功';
                                modalParams.btnVisible = false;
                                getTableData();
                            }
                            Object.assign(modalData, modalParams);
                        });
                    }
                } else {
                    modalVisible.value = true;
                    modalParams.noticeType = 'warning';
                    if (!isQualified) {
                        modalParams.content = '当前存在不可提交的零售信息';
                        isQualified = true;
                    } else {
                        modalParams.content = '当前无可提交的零售';
                    }
                    modalParams.btnVisible = false;
                    Object.assign(modalData, modalParams);
                }
            } else {
                modalVisible.value = true;
                modalParams.noticeType = 'warning';
                modalParams.content = '当前无可提交的零售';
                modalParams.btnVisible = false;
                Object.assign(modalData, modalParams);
            }
        };

        return {
            noLicense,
            noRelationProof,
            columns,
            tableData,
            isOpen,
            searchParams,
            pagination,
            resetEvent,
            getTableData,
            pageChange,
            sizeChange,
            params,
            state,
            hasSelected,
            pictureModal,
            viewPicEvent,
            downloadImg,
            closePictureModal,
            isBatchVisible,
            modalVisible,
            visible,
            saleArray,
            open,
            close,
            closeBatch,
            tableHeight,
            tableWidth,
            isSubVisible,
            isInvoice,
            isLicense,
            isRelationProof,
            selectInfo,
            modalData,
            modalParams,
            closeSub,
            getModalInfo,
            closeModal,
            subLicense,
            searchCon,
            getTableScroll,
            onSelectChange,
            isSubmit,
            isResetModal,
            isRestCount,
            uploadVerify,
            batchRef,
            batchActiveKey,
            isProveVisible,
            closeProve,
            proveRef,
            submitInfo,
            idList,
            brand,
        };
    },
});
