
import { defineComponent, ref, reactive, computed } from 'vue';
import { FileItem } from '@/API/types';
import { submitProveFile } from '@/API/delayCallback/overdueSearch';
import { getManagerApi } from '@/API/claim/delaerClaim/delaer';
import { getAllBrands, findBuByBrand } from '@/API/rv/rvDealer';
import { useStore } from 'vuex';

export default defineComponent({
  components: {},
  emits: ['close-prove-visible', 'get-modal-info'],
  props: {
    isProveVisible: {
      type: Boolean,
      required: true,
      default: false
    },
    idList: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    brand: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, context) {
    // 获取相关数据
    const modal = (props as any).isProveVisible;
    const vinList = (props as any).idList;
    const proveVisible = ref<boolean>(false);

    // 文件上传
    const proveUploadComplete = ref<boolean>(true);
    const proveFormData = new FormData();
    const proveFileName = ref<string>('');
    const proveFileSize = ref<string>('');
    const proveFile = ref<string>('');
    // 进度条
    const provePercent = ref<number>(0);
    const proveContent = ref<string>('0%完成');
    const proveProStatus = ref<string>('normal');
    const isProveSuccess = ref<boolean>(false);
    const proveConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          provePercent.value = complete;
          proveContent.value = complete + '%完成';
        }
      }
    };
    const proveFileData = reactive([]);
    // 弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: '',
      content: '',
      btnVisible: false
    });
    const manager = ref<string>('');

    const close = () => {
      context.emit('close-prove-visible', { isSubVisible: false });
    };

    const beforeUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        const params = reactive({
          noticeType: '',
          content: '',
          btnVisible: false
        });
        const isPdf = file.type === 'application/pdf';
        if (!isPdf) {
          params.noticeType = 'error';
          params.content = '文件格式错误，请按照PDF格式进行文件上传';
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit('get-modal-info', {
            modalVisible: true,
            modalData: modalData
          });
          proveProStatus.value = 'exception';
          return reject(false);
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = 'error';
          params.content = '文件大小必须小于5MB!';
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit('get-modal-info', {
            modalVisible: true,
            modalData: modalData
          });
          proveProStatus.value = 'exception';
          return reject(false);
        }
        const fileLength = fileList.length;
        if (fileLength > 1) {
          params.noticeType = 'error';
          params.content = '请上传单张证明';
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit('get-modal-info', {
            modalVisible: true,
            modalData: modalData
          });
          proveProStatus.value = 'exception';
          return reject(false);
        }
        return resolve(true);
      });
    };

    const proveCustomRequest = (source: any) => {
      proveFormData.append('file', source.file);
      proveFileName.value = source.file.name;
      proveFile.value = source.file.name;
      proveFileSize.value = (source.file.size / 1024 / 1024).toFixed(2) + 'MB';
      proveUploadComplete.value = false;
    };
    // 上传报错 关闭弹窗 重置相应数据
    const isResetModal = (param: { continue: boolean }) => {
      const isCloseModal = param.continue;
      if (isCloseModal) {
        if (proveProStatus.value == 'exception') {
          proveUploadComplete.value = true;
          proveProStatus.value = 'normal';
          provePercent.value = 0;
          proveFormData.delete('files');
        }
      }
    };

    const submitProve = async () => {
      try {
        const res = await submitProveFile(proveConfig, proveFormData);
        if (res.code !== '0') {
          isProveSuccess.value = false;
          proveProStatus.value = 'exception';
          provePercent.value = 95;
          proveFileSize.value = '';
          proveContent.value = '失败';
          modalParams.content = res.message;
        } else {
          proveProStatus.value = 'normal';
          modalParams.content = '上传成功！';
          isProveSuccess.value = true;
        }
      } catch (e) {
        isProveSuccess.value = false;
        proveProStatus.value = 'exception';
        provePercent.value = 95;
        proveFileSize.value = '';
        modalParams.content = '上传失败';
      }
    };
    const submitFile = async () => {
      if (proveFile.value && manager.value) {
        proveFormData.append('idList', vinList);
        proveFormData.append('regionManager', manager.value);
        await submitProve();
        if (isProveSuccess.value) {
          modalParams.noticeType = 'success';
          modalParams.btnVisible = false;
          close();
        } else {
          modalParams.noticeType = 'error';
          modalParams.btnVisible = false;
        }
      } else {
        if (!proveFile.value) {
          modalParams.noticeType = 'warning';
          modalParams.btnVisible = false;
          modalParams.content = '请上传延期证明材料';
        } else {
          modalParams.noticeType = 'warning';
          modalParams.btnVisible = false;
          modalParams.content = '请选择对应的区域审批人';
        }
      }
      Object.assign(modalData, modalParams);
      context.emit('get-modal-info', {
        modalVisible: true,
        modalData: modalData
      });
    };

    // 区域经理
    const regionManager = ref<string>('');
    const regionManagerList = ref<any[]>([]);

    const store = useStore();
    const dealerCode = computed(() => {
      return (store.state.user as any).organization.entityCode;
    });
    const brand = (props as any).brand;
    let buNameList: any[] = [];
    const brandIdMaps = {};

    const getManagerList = (name: string) => {
      return getManagerApi({
        dealerCode: dealerCode.value,
        programBu: name
      });
    };

    getAllBrands()
      .then((res: any) => {
        res.forEach((ele: any) => {
          brandIdMaps[ele.brandNameEn] = ele.id;
        });
      })
      .then((_: any) => {
        console.log(brand, 'brand');
        return findBuByBrand({ brandIds: brandIdMaps[brand] }).then((res: any) => {
          const noUseBus = ['Fleet', 'CPO', 'CompanyCar'];
          buNameList = res.filter((ele: any) => {
            return !noUseBus.includes(ele.nameEn);
          });
        });
      })
      .then((_) => {
        const fns: any[] = [];
        buNameList.map((bu: any) => {
          fns.push(getManagerList(bu.nameEn));
        });
        Promise.all(fns).then((result: any) => {
          const usrIds: string[] = [];
          //! 优化
          const usrList = result.filter(Boolean).reduce((usrs: any, item: any) => {
            if (!usrIds.includes(item.usrid)) {
              usrIds.push(item.usrid);
              return [...usrs, ...item];
            }
            return usrs;
          }, []);
          regionManagerList.value = usrList;
        });
      });
    return {
      modal,
      vinList,
      proveVisible,
      close,
      beforeUpload,
      proveUploadComplete,
      submitFile,
      submitProve,
      proveCustomRequest,
      provePercent,
      proveConfig,
      proveProStatus,
      proveContent,
      isProveSuccess,
      modalData,
      proveFileData,
      modalParams,
      proveFileName,
      proveFileSize,
      manager,
      regionManager,
      regionManagerList,
      proveFile,
      isResetModal
    };
  }
});
