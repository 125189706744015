export default (column: Array<any>, data: Array<any>, customerColumn?: Array<string>) => {
  const tempColumn = column
  const tempWithds = new Object()
  if (customerColumn?.length) {
    customerColumn.forEach(item=> {
      tempWithds[item] = 0
      data.forEach(el => {
        tempWithds[item] = String(el[item]).replace(/[\u0391-\uFFE5]/g,"aa").length > tempWithds[item] ? String(el[item]).replace(/[\u0391-\uFFE5]/g,"aa").length : tempWithds[item]
      })
    })
  } else {
   column.forEach(item=> {
      tempWithds[item.dataIndex] = 0
      data.forEach(el => {
        tempWithds[item.dataIndex] = String(el[item.dataIndex]).replace(/[\u0391-\uFFE5]/g,"aa").length > tempWithds[item.dataIndex] ? String(el[item.dataIndex]).replace(/[\u0391-\uFFE5]/g,"aa").length : tempWithds[item.dataIndex]
      })
    })
  }
  for (const key in tempWithds) {
    tempColumn.forEach((item: any) => {
      if (item.dataIndex === key) {
        item.title.replace(/[\u0391-\uFFE5]/g,"aa").length < tempWithds[key] ? item.width = 10 * tempWithds[key] :
        // 表头单元格2个文字 宽度至少是60
        item.width = Math.max(20 * item.title.length, 60)
        return
      }
    })
  }
  return tempColumn
}