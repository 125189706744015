
import { defineComponent, ref, reactive, computed, Ref } from 'vue';
import { FileItem } from '@/API/types';
import { submitInvoiceFile, submitLicenseFile, submitRelationProofFile } from '@/API/delayCallback/overdueSearch';
import { useStore } from 'vuex';
import { RvApprovalStatus } from '@/views/RetailValidation/Dealer/types';
export default defineComponent({
  components: {},
  emits: ['close-sub-visible', 'get-modal-info'],
  props: {
    isSubVisible: {
      type: Boolean,
      default: false
    },
    isInvoice: {
      type: Boolean,
      default: false
    },
    isLicense: {
      type: Boolean,
      default: false
    },
    isRelationProof: {
      type: Boolean,
      default: false
    },
    selectInfo: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, context) {
    const dealerCode = useStore().state.user.organization.entityCode;
    // 获取相关数据
    const modal = (props as any).isSubVisible;

    // 文件上传
    const invoiceUploadComplete = ref<boolean>(true);
    const invoiceFormData = new FormData();
    const invoiceFileName = ref<string>('');
    const invoiceFileSize = ref<string>('');

    const licenseUploadComplete = ref<boolean>(true);
    const licenseFormData = new FormData();
    const licenseFileName = ref<string>('');
    const licenseFileSize = ref<string>('');

    const relationProofUploadComplete = ref<boolean>(true);
    const relationProofFormData = new FormData();
    const relationProofFileName = ref<string>('');
    const relationProofFileSize = ref<string>('');
    // 进度条

    const invoicePercent = ref<number>(0);
    const invoiceProStatus = ref<string>('normal');

    const licensePercent = ref<number>(0);
    const licenseProStatus = ref<string>('normal');

    const relationProofPercent = ref<number>(0);
    const relationProofProStatus = ref<string>('normal');

    const isInvoiceSuccess = ref<boolean>(false);
    const isLicenseSuccess = ref<boolean>(false);
    const isSuccess = ref<boolean>(false);

    const generateUploadConfig = (percent: Ref<number>) => {
      return {
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent.lengthComputable) {
            const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            percent.value = complete;
          }
        }
      };
    };

    const invoiceConfig = generateUploadConfig(invoicePercent);
    const licenseConfig = generateUploadConfig(licensePercent);
    const relationProofConfig = generateUploadConfig(relationProofPercent);

    const invoiceFileData = reactive([]);
    const licenseFileData = reactive([]);
    // 弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: '',
      content: '',
      btnVisible: false
    });

    const close = () => {
      context.emit('close-sub-visible', { isSubVisible: false });
    };

    const beforeUploadWithFileType = (fileTypes: string[]) => {
      const fileTypeCheck = {
        JPG: (file: FileItem) => file.name?.split('.')[1] === 'jpeg' || file.type === 'image/jpeg',
        PDF: (file: FileItem) => file.type === 'application/pdf',
        OFD: (file: FileItem) => file.name && file.name.indexOf('.ofd') > -1
      };

      const beforeUpload = (file: FileItem, fileList: any) => {
        return new Promise((resolve, reject) => {
          const params = reactive({
            noticeType: '',
            content: '',
            btnVisible: false
          });

          let fileTypeError = true;
          for (const fileType of fileTypes) {
            if (fileTypeCheck[fileType](file)) {
              fileTypeError = false;
              break;
            }
          }
          if (fileTypeError) {
            params.noticeType = 'error';
            params.content = `文件格式错误，请按照${fileTypes.join(' ')}格式进行文件上传`;
            params.btnVisible = false;
            Object.assign(modalData, params);
            context.emit('get-modal-info', {
              modalVisible: true,
              modalData: modalData
            });
            return reject(false);
          }

          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            params.noticeType = 'error';
            params.content = '文件大小必须小于5MB!';
            params.btnVisible = false;
            Object.assign(modalData, params);
            context.emit('get-modal-info', {
              modalVisible: true,
              modalData: modalData
            });
            return reject(false);
          }
          const fileLength = fileList.length;
          if (fileLength > 1) {
            params.noticeType = 'error';
            params.content = '每次只能上传一张零售文件';
            params.btnVisible = false;
            Object.assign(modalData, params);
            context.emit('get-modal-info', {
              modalVisible: true,
              modalData: modalData
            });
            return reject(false);
          }
          return resolve(true);
        });
      };
      return beforeUpload;
    };

    const genCustomRequestFunc = (
      formData: FormData,
      fileName: Ref<string>,
      fileSize: Ref<string>,
      complete: Ref<boolean>
    ) => {
      return (source: any) => {
        formData.append('file', source.file);
        fileName.value = source.file.name;
        fileSize.value = (source.file.size / 1024 / 1024).toFixed(2) + 'MB';
        complete.value = false;
      };
    };

    const invoiceCustomRequest = genCustomRequestFunc(
      invoiceFormData,
      invoiceFileName,
      invoiceFileSize,
      invoiceUploadComplete
    );
    const licenseCustomRequest = genCustomRequestFunc(
      licenseFormData,
      licenseFileName,
      licenseFileSize,
      licenseUploadComplete
    );
    const relationProofCustomRequest = genCustomRequestFunc(
      relationProofFormData,
      relationProofFileName,
      relationProofFileSize,
      relationProofUploadComplete
    );

    const submitInvoice = async () => {
      if (props.selectInfo.invoicePath) {
        return;
      }
      const res = await submitInvoiceFile(invoiceConfig, props.selectInfo.vinNo, dealerCode, invoiceFormData);
      if (res.code !== '0') {
        invoiceProStatus.value = 'exception';
        throw res;
      }
    };
    const submitLicense = async () => {
      if (props.selectInfo.licensePath) {
        return;
      }
      try {
        const res = await submitLicenseFile(licenseConfig, props.selectInfo.vinNo, dealerCode, licenseFormData);
        if (res.code !== '0') {
          throw res;
        }
      } catch (e) {
        licenseProStatus.value = 'exception';
        throw e;
      }
    };
    const submitRelationProof = async () => {
      if (!props.selectInfo.relationProofPath && !relationProofFormData.has('file')) {
        return;
      }
      try {
        const res = await submitRelationProofFile(
          relationProofConfig,
          props.selectInfo.vinNo,
          dealerCode,
          relationProofFormData
        );
        if (res.code !== '0') {
          throw res;
        }
      } catch (e) {
        relationProofProStatus.value = 'exception';
        throw e;
      }
    };

    const submitFile = async () => {
      try {
        if (props.isInvoice) await submitInvoice();
        if (props.isLicense) await submitLicense();
        if (props.isRelationProof) await submitRelationProof();
        modalParams.noticeType = 'success';
        modalParams.content = '上传成功！';
        modalParams.btnVisible = false;
        isSuccess.value = true;
        close();
      } catch (e: any) {
        modalParams.content = e.message || '上传失败';
        modalParams.noticeType = 'error';
        modalParams.btnVisible = false;
        isSuccess.value = false;
      }

      Object.assign(modalData, modalParams);
      context.emit('get-modal-info', {
        modalVisible: true,
        modalData: modalData
      });
    };

    const enableUpload = computed(() => {
      // 发票未上传
      if (props.isInvoice) {
        if (!props.selectInfo.invoicePath && invoiceUploadComplete.value) {
          return false;
        }
      }
      // 行驶证未上传
      if (props.isLicense) {
        if (!props.selectInfo.licensePath && licenseUploadComplete.value) {
          return false;
        }
      }
      // 未选择任何上传文件
      if (
        props.isInvoice &&
        invoiceUploadComplete.value &&
        props.isLicense &&
        licenseUploadComplete.value &&
        props.isRelationProof &&
        relationProofUploadComplete.value
      ) {
        return false;
      }
      return true;
    });

    return {
      modal,
      close,
      beforeUploadWithFileType,
      invoiceUploadComplete,
      licenseUploadComplete,
      relationProofUploadComplete,
      submitFile,
      submitInvoice,
      submitLicense,
      invoiceCustomRequest,
      licenseCustomRequest,
      relationProofCustomRequest,
      invoicePercent,
      licensePercent,
      relationProofPercent,
      invoiceConfig,
      licenseConfig,
      invoiceProStatus,
      licenseProStatus,
      relationProofProStatus,
      isInvoiceSuccess,
      isLicenseSuccess,
      isSuccess,
      modalData,
      invoiceFileData,
      licenseFileData,
      modalParams,
      invoiceFileName,
      invoiceFileSize,
      licenseFileName,
      licenseFileSize,
      relationProofFileName,
      relationProofFileSize,
      RvApprovalStatus,
      enableUpload
    };
  }
});
