/*
 * @Author: your name
 * @Date: 2021-06-28 10:11:12
 * @LastEditTime: 2021-10-27 18:22:46
 * @LastEditors: zhengda.lv
 * @Description: In User Settings Edit
 * @FilePath: \oasis-fontend\src\API\rv\rvDealer.ts
 */
import {
  fetchGet,
  fetchPost,
  fetchPostPic,
  fetchGetMess,
  fetchPutPic,
} from "../index";
import {
  DealerOverviewData,
  DealerFailReasonData,
  DealerStatusData,
} from "@/views/RetailValidation/Dealer/types";
import { SelectData } from "@/views/RetailValidation/Sicco/types";
export const getBasicDetail = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/basic/apply/dealer/retailInfo", params);
};
export const getOperRecordDetail = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/basic/retail/operationDetails", params);
};
export const getDealerOverview = (
  brands: string[]
): Promise<DealerOverviewData> => {
  return fetchPost("/rvapi/basic/overview/dealer/v2", brands);
};
export const getRVTable = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/basic/apply/dealer/mulquery", params);
};
export const getFailReasonData = (
  params = {}
): Promise<DealerFailReasonData[]> => {
  return fetchGet("/rvapi/basic/retail/failReasonView", params);
};
export const getRetailHistory = (params = {}): Promise<any> => {
  return fetchGetMess("/saleapi/noAuth/rt/findByVin", params);
};
export const getlicenseView = (params = {}): Promise<string> => {
  return fetchGet("/rvapi/basic/certificate/licenseView", params);
};
export const getInvioceView = (params = {}): Promise<string> => {
  return fetchGet("/rvapi/basic/certificate/invoiceView", params);
};
export const getRelationProofView = (params = {}): Promise<string> => {
  return fetchGet("/rvapi/basic/certificate/relationProofView", params);
};
export const submitInvoiceFile = (
  invoiceConfig = {},
  vinNo = "",
  dealerCode = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    `/rvapi/basic/certificate/invoice/upload?vinNo=${vinNo}&dealerCode=${dealerCode}`,
    params,
    invoiceConfig
  );
};
export const submitLicenseFile = (
  licenseConfig = {},
  vinNo = "",
  dealerCode = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    `/rvapi/basic/certificate/license/upload?vinNo=${vinNo}&dealerCode=${dealerCode}`,
    params,
    licenseConfig
  );
};
export const submitRelationProofFile = (
  relationProofConfig = {},
  vinNo = "",
  dealerCode = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    `/rvapi/basic/certificate/relationProof/upload?vinNo=${vinNo}&dealerCode=${dealerCode}`,
    params,
    {
      ...relationProofConfig,
      headers: {
        "code-handler": "ignore",
      }
    }
  );
};
export const batchInvoiceSubmit = (
  invoiceConfig = {},
  params = {}
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/certificate/batchInvoice/upload",
    params,
    invoiceConfig
  );
};
export const batchLicenseSubmit = (
  licenseConfig = {},
  params = {}
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/certificate/batchLicense/upload",
    params,
    licenseConfig
  );
};
export const batchInvoiceFile = (
  invoiceConfig = {},
  batchNo = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/certificate/batchInvoice/upload?batchNo=" + batchNo,
    params,
    invoiceConfig
  );
};
export const batchLicenseFile = (
  licenseConfig = {},
  batchNo = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/certificate/batchLicense/upload?batchNo=" + batchNo,
    params,
    licenseConfig
  );
};
export const batchRelationProofFile = (
  licenseConfig = {},
  batchNo = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/certificate/relationProof/batchUpload?batchNo=" + batchNo,
    params,
    licenseConfig
  );
};
export const cancelUploadFile = (batchNo = ""): Promise<any> => {
  return fetchGetMess(
    "/rvapi/basic/certificate/cancelUpload?batchNo=" + batchNo
  );
};
export const getApprovalStatus = (): Promise<DealerStatusData[]> => {
  return fetchGet("/rvapi/stand/approvalStatus/dicDealer");
};
export const batchFileSubmit = (params = {}): Promise<any> => {
  return fetchGetMess("/rvapi/basic/apply/progress", params);
};
export const batchSubmitResult = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/basic/apply/submit", params);
};

export const getCheckStatus = (): Promise<DealerStatusData[]> => {
  return fetchGet("/rvapi/stand/checkStatus/dicDealer");
};
export const getvinMdfCheckFlag = (params = {}): Promise<boolean> => {
  return fetchGet(`/rvapi/basic/retail/vinMdfCheckFlag`, { params });
};
export const getfileSize = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/basic/certificate/fileSize", params);
};
export const sendEditInfo = (
  editConfig = {},
  id = 0,
  usage = "",
  customerName = "",
  vinNo = "",
  dealerCode = "",
  invoiceDate = "",
  licenseDate = "",
  retailDate = "",
  price = "",
  params = {}
): Promise<any> => {
  return fetchPutPic(
    `/rvapi/basic/apply/modification?id=${id}&usage=${usage}&customerName=${customerName}&vinNo=${vinNo}&dealerCode=${dealerCode}&invoiceDate=${invoiceDate}&licenseDate=${licenseDate}&retailDate=${retailDate}&price=${price}`,
    params,
    editConfig
  );
};
export const postCallback = (params: number): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/apply/callback?vehicleRetailApplyId=" + params
  );
};

export const checkDate = (params = {}): Promise<any> => {
  return fetchGetMess("/rvapi/basic/apply/check", params);
};

export const getSelectData = (): Promise<SelectData> => {
  return fetchGet("/rvapi/basic/data/mainData");
};
//撤回查询申报项目信息
export const getWithdrawInfo = (recordId: number): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/apply/delayCallBackSearchClaimInfo?vehicleRetailApplyId=" +
      recordId
  );
};
//车辆撤回对销售业绩影响
export const getWithdrawComletedInfo = (recordId: number): Promise<any> => {
  return fetchPostPic(
    "/rvapi/basic/apply/delayCallBackSearchInfluence?vehicleRetailApplyId=" +
      recordId
  );
};
//撤回证明材料上传
export const postCallbackUpload = (
  data = { recordId: -1, regionManager: "" },
  params: any
): Promise<any> => {
  return fetchPostPic(
    `rvapi/basic/apply/uploadCertifyAndDoCallBack?vehicleRetailApplyId=${data.recordId}&regionManager=${data.regionManager}`,
    params
  );
};
//特殊车辆用途:
export const getSpecialUsageItem = (): Promise<any> => {
  return fetchGetMess("/saleapi/noAuth/specialUsage");
};
//rv正常撤回
export const rvCallBackNormal = (
  vehicleRetailApplyId: number
): Promise<any> => {
  return fetchPostPic(
    "rvapi/basic/apply/rvCallBackNormal",
    {},
    { params: { vehicleRetailApplyId } }
  );
};

export const getAllBrands = (): Promise<SelectData> => {
  return fetchGet("/vapi/vehicleModelInfo/brands");
};
export const findBrandByBu = (buIds: number[]) => {
  return fetchGet("/vapi/noAuth/findBrandByBu", {
    params: {
      buIds,
    },
  });
};
export const findBuByBrand = (params: any): Promise<SelectData> => {
  return fetchGet("/vapi/noAuth/findBuByBrand", { params });
};
export const lastTimeResults = (dealerCode: string, type: "0" | "1" | "2") => {
  return fetchGet("/rvapi/basic/certificate/lastTimeResults", {
    params: {
      dealerCode,
      type,
    },
  });
};
export const continueUpload = (dealerCode: string, type: "0" | "1" | "2") => {
  return fetchGet("/rvapi/basic/certificate/continueUpload", {
    params: {
      dealerCode,
      type,
    },
  });
};
export const relationProofPreCheck = (vinNo: string, dealerCode: string): Promise<any> => {
  return fetchPost("/rvapi/basic/certificate/relationProof/button-check", {}, {
    params: {
      vinNo,
      dealerCode,
    },
    codeHandler: 'ignore'
  });
};