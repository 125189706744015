
import { computed, defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    submitLabel: {
      type: String,
      default: "是",
    },
    cancelLabel: {
      type: String,
      default: "否",
    },
    modalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    modalData: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    "close-modal-visible",
    "is-reset-modal",
    "is-reset-count",
    "handle-delay-withdraw",
  ],
  setup(props, context) {
    const modalVis = (props as any).modalVisible;
    const modal = (props as any).modalData;
    const showContent = computed(() => {
      return Object.keys(modal).includes("approvedNo");
    });
    const muchContent = sessionStorage.getItem("muchContent");
    const muchDet = ref([]);
    const modelContentVisible = ref<boolean>(true);
    if (muchContent) {
      muchDet.value = JSON.parse(muchContent);
    } else {
      console.log("muchContent==false");
    }
    const submit = () => {
      context.emit("close-modal-visible", {
        isVisible: false,
        continue: true,
      });
      sessionStorage.setItem("continue", "true");
    };

    const cancel = () => {
      context.emit("close-modal-visible", {
        isVisible: false,
        continue: false,
      });
      sessionStorage.setItem("continue", "");
      sessionStorage.setItem("muchContent", "");
      context.emit("is-reset-modal", { continue: true });
      if (
        modal.content.indexOf("图片") > -1 ||
        modal.content.indexOf("文件格式错误") > -1 ||
        modal.content.indexOf("请重新上传")
      ) {
        context.emit("is-reset-count", { continue: true });
      }
    };
    const handleDelayWithdraw = () => {
      context.emit(
        "handle-delay-withdraw",
        modal.recordId,
        modal.vinNo,
        modal.retailDate
      );
      context.emit("is-reset-modal", { continue: true });
      context.emit("close-modal-visible", {
        isVisible: false,
        continue: true,
      });
    };

    let closetDurationTimer: number | undefined = undefined;

    watch(
      () => props.modalVisible,
      (visible: boolean) => {
        if (props.duration && visible) {
          closetDurationTimer = setTimeout(() => {
            cancel();
          }, props.duration * 1000);
        } else {
          if (closetDurationTimer) {
            clearTimeout(closetDurationTimer);
          }
        }
      },
      {
        immediate: true,
      }
    );

    return {
      showContent,
      modalVis,
      modal,
      submit,
      cancel,
      muchContent,
      muchDet,
      modelContentVisible,
      handleDelayWithdraw,
    };
  },
});
