import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from '../index';
export const getSearchResult = (params = {}): Promise<any> => {
  return fetchPost('/rvapi/delay/mulquery', params)
}
// 单张上传
export const licenseUpload = (licenseConfig = {}, vinNo = '', params = {}): Promise<any> => {
  return fetchPostPic('/rvapi/vl/handlefile/license/upload?vinNo=' + vinNo, params, licenseConfig)
}
// 单张OCR识别
export const licenseSubmit = (vinNo = '', params = {}): Promise<any> => {
  return fetchPostPic('/rvapi/vl/handlefile/license/upload?vinNo=' + vinNo, params)
}
// 单张取消上传
export const cancelUpload = (vinNo = ''): Promise<any> => {
  return fetchGet('/rvapi/delay/cancelUpload?vinNo=' + vinNo)
}
// 单张提交结果
export const licenseResult = (vinNo = ''): Promise<any> => {
  return fetchGetMess('/rvapi/vl/singleSubmitResult?vinNo=' + vinNo)
}
// 获取文件大小
export const getfileSize = (params = {}): Promise<any> => {
  return fetchPost('/rvapi/basic/certificate/fileSize', params)
}
//批量行驶证上传
export const batchLicenseFile = (licenseConfig = {}, batchNo = '', params = {}): Promise<any> => {
  return fetchPostPic('/rvapi/delay/batchUpload/license?batchNo=' + batchNo, params, licenseConfig)
}
//批量发票上传
export const batchInvoiceFile = (licenseConfig = {}, batchNo = '', params = {}): Promise<any> => {
  return fetchPostPic('/rvapi/delay/batchUpload/invoice?batchNo=' + batchNo, params, licenseConfig)
}
//批量行驶证上传
export const batchRelationProofFile = (relationProofConfig = {}, batchNo = '', params = {}): Promise<any> => {
  return fetchPostPic('/rvapi/delay/relationProof/batchUpload?batchNo=' + batchNo, params, relationProofConfig)
}
//获取提交结果
export const batchSubmitResult = (params = {}): Promise<any> => {
  return fetchGet('/rvapi/basic/apply/submit', params)
}
//获取提交进度
export const batchFileSubmit = (params = {}): Promise<any> => {
  return fetchGetMess('/rvapi/basic/apply/progress', params)
}
//取消批量上传
export const cancelUploadFile = (batchNo = ''): Promise<any> => {
  return fetchGetMess('/rvapi/delay/cancelUpload?batchNo=' + batchNo)
}
//单车行驶证提交
export const submitInvoiceFile = (invoiceConfig = {}, vinNo = '', dealerCode = '', params = {}): Promise<any> => {
  return fetchPostPic(`./rvapi/delay/upload/invoice?vinNo=${vinNo}&dealerCode=${dealerCode}`, params, invoiceConfig)
}
//单车发票提交
export const submitLicenseFile = (licenseConfig = {}, vinNo = '', dealerCode = '', params = {}): Promise<any> => {
  return fetchPostPic(`./rvapi/delay/upload/license?vinNo=${vinNo}&dealerCode=${dealerCode}`, params, licenseConfig)
}
//单车关系证明提交
export const submitRelationProofFile = (relationProofConfig = {}, vinNo = '', dealerCode = '', params = {}): Promise<any> => {
  return fetchPostPic(`./rvapi/delay/relationProof/upload?vinNo=${vinNo}&dealerCode=${dealerCode}`, params, {
    ...relationProofConfig,
    headers: {
      "code-handler": "ignore",
    }
  })
}
//单车撤回
export const singleLicenseCallback = (vinNo: '', dealerCode = ''): Promise<string> => {
  return fetchPostPic(`./rvapi/vl/dealer/vechileCallBack?vinNo=${vinNo}&dealerCode=${dealerCode}`)
}
//批量提交
export const batchVLSubmit = (params: {}): Promise<any> => {
  return fetchPostPic('./rvapi/vl/dealer/batchVechileSubmit', params)
}

export const getPicBasic = (params: {}): Promise<any> => {
  return fetchGet('./rvapi/delay/viewFile', params)
}
// 上传证明
export const submitProveFile = (proveConfig = {}, params = {}): Promise<any> => {
  return fetchPostPic(`/rvapi/delay/upload/certify`, params, proveConfig)
}
// 提交功能
export const submitInformation = (params = {}): Promise<any> => {
  return fetchPostPic('/rvapi/delay/submit', params)
}